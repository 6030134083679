import React, { useState, useEffect } from 'react';
import '../CYBB.css';
import floatingImage from '../images/BradBoxOffice.png';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Features2x3
} from '../ui-components';
import {
  BackButton
} from '../ui-components';
import { View } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify'
import { Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';
import jsonData from '../data/CYBBMovieData.json';
import jsonQuotes from '../data/CYBBFinalQuotes.json'
import OpenAI from "openai";
import axios  from 'axios';

function CYBBPage() {
  const [thisRoundTitle, setThisRoundTitle] = useState('');
  const [thisRoundYear, setThisRoundYear] = useState('');
  const [thisRoundGenre, setThisRoundGenre] = useState('');
  const [thisRoundDetails, setThisRoundDetails] = useState('');
  const [thisRoundGenreImage, setThisRoundGenreImage] = useState('');
  const [thisRoundRevenue, setThisRoundRevenue] = useState(0);
  const [thisRoundBradChat, setThisRoundBradChat] = useState('');
  const [thisRoundBradsGuess, setThisRoundBradsGuess] = useState('');
  const [thisRoundBradsGuessConverted, setThisRoundBradsGuessConverted] = useState('');
  const [thisRoundPlayerGuess, setThisRoundPlayerGuess] = useState('Correct');
  const [finalRoundBradQuote, setFinalRoundBradQuote] = useState('');
  const [cybbPlayersScore, setCYBBPlayersScore] = useState(0);
  const [cybbBradsScore, setCYBBBradsScore] = useState(0);
  const [cybbStage, setCYBBStage] = useState('start');
  const [roundChangeTracker, setRoundChangeTracker] = useState(-2);
  const [gameDifficulty, setGameDifficulty] = useState("Normal");
  const [gameDifficultyString, setGameDifficultyString] = useState("Normal");
  const [staleMovies, setStaleMovies] = useState([]);
  const [completion, setCompletion] = useState("");



  const advanceGameState = () => {
    switch (cybbStage) {
      case 'start':
        setCYBBStage('roundOneGuess')
        break;
      case 'roundOneGuess':
        setCYBBStage('roundOneResults')
        break;
      case 'roundOneResults':
        setCYBBStage('roundTwoGuess')
        break;
      case 'roundTwoGuess':
        setCYBBStage('roundTwoResults')
        break;
      case 'roundTwoResults':
        setCYBBStage('roundThreeGuess')
        break;
      case 'roundThreeGuess':
        setCYBBStage('roundThreeResults')
        break;
      case 'roundThreeResults':
        setCYBBPlayersScore(0);
        setCYBBBradsScore(0);
        setCYBBStage('start')
        break;
      default:
        break;
    }
  }
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true // This is the default and can be omitted
  });
  useEffect(() => {
    setRoundChangeTracker(prevTracker => prevTracker + 1);
    console.log("big!")
  }, [thisRoundTitle]);
  useEffect(() => {
    setRoundChangeTracker(prevTracker => prevTracker + 1);
    console.log("big2!")
  }, [thisRoundDetails]);
   
  useEffect(() => {
    if (staleMovies.length > 20){
      setStaleMovies([]);
      console.log("Cleared the movie list!")
    }
  }, [staleMovies]);

  useEffect(() => {
    if (roundChangeTracker === 2){
      requestOpenAI();
      setRoundChangeTracker(0);
      console.log("RoundChangeTrigger")
    }
  }, [roundChangeTracker]);
  const apiUrl = "https://v2vtdpfp05.execute-api.us-east-2.amazonaws.com/default"
  const requestOpenAI = () => {
    if (gameDifficulty === "Normal"){
      setGameDifficultyString("You always round your guesses to the nearest 1,000,000. Your guesses are always half or double the actual value. You always round your guesses to the nearest 1,000,000. Your guesses are always half or double the actual value.")
    } 
    if (gameDifficulty === "Hard"){
      setGameDifficultyString("Brad always rounds up or down to the nearest 10,000. Your answer should always be rounded up or down to the nearest 10,000 value. You should always give answers rounded to 10,000, even if it makes your answers inaccurate.")
    }
    
    const data = {
      "gameDifficulty": gameDifficulty,
      "thisRoundTitle": thisRoundTitle,
      "gameDifficultyString": "value2",
      "thisRoundYear": thisRoundYear,
      "thisRoundGenre": thisRoundGenre,
      "thisRoundDetails": thisRoundDetails,
    };
    axios.defaults.headers.post['Content-Type'] ='application/json';
    axios.post(apiUrl, data, {
      headers: {
        'Content-Type': 'application/json'
    }
    })
      .then(response => {
        const responseBody = JSON.parse(response.data.body);

        // Now you can access the properties directly
        setThisRoundBradsGuess(responseBody.message);
        console.log('Response from Lambda:', responseBody.setThisRoundBradChat);
        console.log('Full Response from Lambda:', response);
        setThisRoundBradChat(responseBody.setThisRoundBradChat)
      })
      .catch(error => {
          console.error('Error calling API:', error);
      });
  }
  
  // Determine who should get a point based on if the guess was correct or not
  const determinePointAward = (guess) => {
    if ((guess === 'Higher' && thisRoundBradsGuess < Number(thisRoundRevenue.replace(/,/g, ""))) || (guess === "Lower" && thisRoundBradsGuess > Number(thisRoundRevenue.replace(/,/g, "")))) {
      setCYBBPlayersScore(cybbPlayersScore + 1);
      setThisRoundPlayerGuess('Correct');
    } 
    else if (thisRoundBradsGuess === Number(thisRoundRevenue.replace(/,/g, ""))){
      setCYBBPlayersScore(cybbPlayersScore + 1);
      setCYBBBradsScore(cybbBradsScore + 1);
      setThisRoundPlayerGuess('Tie');
    }
    else{
      setCYBBBradsScore(cybbBradsScore + 1);
      setThisRoundPlayerGuess('Incorrect');
    }


    console.log(guess + "..." + thisRoundBradsGuess + "..." + Number(thisRoundRevenue.replace(/,/g, "")))
    advanceGameState();
  }

  // Function to get a random item
  const getRandomItemFilter = (array, filterArray) => {
    console.log('Array:', array); // Debugging output
    console.log('Filter Array:', filterArray); // Debugging output

    // Ensure array and filterArray are properly initialized
    if (!array || !filterArray) {
      console.error('Array or filter array is undefined');
      return null;
    }
    const filteredArray = array.filter(item => !filterArray.includes(item.Series_Title));
    if (filteredArray.length === 0) {
      return null; // or handle this case as needed
    }
    const randomIndex = Math.floor(Math.random() * filteredArray.length);
    const randomResult = filteredArray[randomIndex]
    setStaleMovies([...staleMovies, randomResult.Series_Title]);
    console.log("The following movies should not show up going forward: " + staleMovies)
    return randomResult;
  };
  const getRandomItem = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };
  const chooseDifficulty = (string) => {
    setGameDifficulty(string);
    generateNewGuess();
  };

  // Function to load and set random data
  const loadRandomData = () => {
    const randomItem = getRandomItemFilter(jsonData, staleMovies);
    
    setThisRoundTitle(randomItem.Series_Title);
    setThisRoundYear(randomItem.Released_Year);
    setThisRoundGenre(randomItem.Genre);
    setThisRoundDetails(randomItem.Overview);
    setThisRoundRevenue(randomItem.Gross);
    console.log("Bing!" + thisRoundTitle)
  };

  const generateNewGuess = () => {
    const randomItemGuess = getRandomItem(jsonData);
    setThisRoundBradsGuess(0);
    setThisRoundBradChat("[Loading]")
    loadRandomData();
    console.log("Generate new guess")
    advanceGameState();
    //setThisRoundBradsGuess(Number(randomItemGuess.Gross.replace(/,/g, "")));
    //advanceGameState();
  }
  // Load random data on component mount
  

  useEffect(() => {
    console.log("Converting" + thisRoundBradsGuess + " to USD!")
    setThisRoundBradsGuessConverted(Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // You can add more options here if needed
  }).format(thisRoundBradsGuess))
  }, [thisRoundBradsGuess]);
  const generateFinalQuote = () => {
    
    const randomItem = getRandomItem(jsonQuotes, staleMovies);
    if (cybbPlayersScore > cybbBradsScore){
      return (randomItem.PlayerWins)
    }
    else if (cybbPlayersScore < cybbBradsScore){
      return (randomItem.BradWins)
    }
    else{
      return (randomItem.TieGame)
    }
  }
  const thisRoundGenreImagePath = require('../images/Genre' + thisRoundGenre + '.png');

  Amplify.configure(awsconfig)
  console.log("Data Page");
  const navigate = useNavigate();

  return (
    <div className="App">
      <TransitionGroup>
      {(cybbStage === 'start') && (
        <CSSTransition key="question" timeout={250} classNames="fade">
          <header className="App-header">
            <div class="content">
            
            <div class="image-container">
            <div class="image-title">Brad BoxOffice</div>
                <img src={floatingImage} alt="Floating Image" class="floating-image" />
              </div>    
                  <div class="start-text">
                  <h1>Can You Beat Brad?</h1>
                  <div class="text-content">
                  <p>Brad BoxOffice will guess a movie's box office gross earnings.</p>
                  <p>Is the real number higher, or lower?</p>
                  <br></br><br></br><br></br><br></br>
                  
                  <div class="fun-button">
                  Choose a difficulty: <button onClick={() =>chooseDifficulty("Normal")}>Normal</button> OR <button onClick={() =>chooseDifficulty("Hard")}>Expert</button>
                    </div>          
                  </div>
                  <div class= "small-text">
                  <br></br>
                  
                  <p>Movie summaries and earnings are gathered from IMDb's Gross US & Canada public data.</p>
                  <p>Final score responses are pre-generated.</p>
                  
                  <p>While Brad's fine-tuning training data excludes movies in CYBB, the base model data may include some CYBB movies.</p>

                  
                  <br></br>
                  <p>Contact@EmitApps.net</p>
                  </div>
                  </div>
              </div>
            </header>
        </CSSTransition>
      )}
       {(cybbStage === 'roundOneGuess' || cybbStage === "roundTwoGuess" || cybbStage === "roundThreeGuess") &&(
         <CSSTransition key="question" timeout={250} classNames="fade">
          <header className="App-header">
            <div class="content">
            
          
            <div class="image-container">
                <div class="image-title">Brad BoxOffice</div>
                <img src={floatingImage} alt="Floating Image" class="floating-image" />
              </div>
              <div class="player-info">
                <div class="fun-image-container">
                  <img src={thisRoundGenreImagePath} alt="Genre" class="fun-image" />
                </div>
                <div class="text-content">
                  <div class="quick-stats">
                    <p>Genre: {thisRoundGenre}</p>
                    <p>Release Year: {thisRoundYear}</p>
                    <p>Title: {thisRoundTitle}</p>
                  </div>
                  <div class="movie-details">
                    <p>Summary: {thisRoundDetails}</p>
                  </div>
                  <div class="brads-guess">
                  {
                        thisRoundBradsGuess > 0 &&(
                        <>
                        
                        <p>Brad: {thisRoundBradChat}</p>
                        </>)
                  }
                  {
                        thisRoundBradsGuess <= 0 &&(
                        <>
                        
                        <p>Brad is thinking...</p>
                        </>)
                  } 
                  
                  </div>
                </div>
                <div class="fun-button">
                  {
                        thisRoundBradsGuess > 0 &&(
                        <>
                        
                        <button onClick={() => determinePointAward("Higher")}>
                            HIGHER THAN {thisRoundBradsGuessConverted}
                        </button>
                        <p>OR</p>
                        <button onClick={() =>determinePointAward("Lower")}>LOWER THAN {thisRoundBradsGuessConverted}</button>
                        </>)}
                </div>
              </div>
            </div>
          </header>
          </CSSTransition>
          )}
        {(cybbStage === 'roundOneResults' || cybbStage === "roundTwoResults" || cybbStage === "roundThreeResults") &&(
          <CSSTransition key="question" timeout={250} classNames="fade">
          <header className="App-header">
            <div class="content">
            
          
              <div class="image-container">
                <div class="image-title">Brad BoxOffice</div>
                <img src={floatingImage} alt="Floating Image" class="floating-image" />
              </div>
              <div class="player-info">
                <div class="text-content">
                  <div class="quick-stats">
                    <p>{thisRoundPlayerGuess === 'Correct' ? 'You win this round!' : (thisRoundPlayerGuess === 'Tie' ? 'Brad guessed the exact revenue! You both get a point!' : 'Brad won this round.')}</p>
                    <p>Correct Gross Revenue: ${thisRoundRevenue}.00</p>
                    <p>{cybbStage === "roundThreeResults" ? 'Final Score: ' + cybbPlayersScore + '-' + cybbBradsScore : 'Current Score: ' + cybbPlayersScore + '-' + cybbBradsScore}</p>
                    
                    
                    {
                        cybbStage === "roundThreeResults" &&(
                        <>
                        
                        <p>{cybbPlayersScore > cybbBradsScore ? 'Congratulations! You beat Brad!' : cybbPlayersScore < cybbBradsScore ? 'Brad won this game.' : "The game ended in a tie!"}</p>
                        </>)}
                  </div>

                  <div class="brads-guess">
                      <p>{cybbStage === 'roundThreeResults' ? "Brad: " + generateFinalQuote() : thisRoundPlayerGuess === 'Tie' ? 'Brad is not surprised.' : thisRoundPlayerGuess === 'Correct' ? 'Brad scoffs.' : "Brad's smug grin grows."}</p>
                  </div>
                </div>
                <div class="fun-button">
                  <button onClick={generateNewGuess}>{cybbStage === "roundThreeResults" ? 'WELL PLAYED!' : 'NEXT ROUND'}</button>
                </div>
              </div>
            </div>
          </header>
          </CSSTransition>
          )}
          </TransitionGroup>
        </div>
  );
}

export default CYBBPage;