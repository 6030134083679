import logo from './logo.svg';
import './App.css';
import React, { useState} from 'react';
import ReactDOM from 'react-dom';
import { HomeCreateForm } from './ui-components'
import { BrowserRouter as Router, Route, Routes, useNavigate, Link  } from 'react-router-dom';
import {
  AVNeeds 
 } from './ui-components';
 import {
  AVNeedsCustom 
 } from './lca-custom';
import { SelectField } from '@aws-amplify/ui-react';
import { getOverrideProps } from './ui-components/utils';
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";
import {
  AVRequest 
 } from './ui-components';
 import {
  useDataStoreCreateAction,
  useStateMutationAction,
} from "./ui-components/utils";
 import { Button } from '@aws-amplify/ui-react';
 import { AVInfo } from "./models";
import { schema } from "./models/schema";
import DataAnalysisPage from './pages/DataAnalysisPage';
import CYBBPage from './pages/CYBBPage';

import HomePage from './pages/HomePage';
import SchedulingPage from './pages/SchedulingPage';



function App() {
  function ButtonLink({ to, children }) {
    return <Link to={to}><button>{children}</button></Link>;
  }
  
  return (

  <div className="App">

  
  <header className="App-header"> 
  
  
    
    <Routes>

      <Route path="/" element={<HomePage/>} />
      <Route path="/CYBB" element={<CYBBPage/>} />

      </Routes>
    </header>
  </div>

  );
  
}


export default App;
