import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import {
    Features2x3 
   } from '../ui-components';
   import {
    MainNav 
   } from '../ui-components';

function HomePage() {
    console.log("Home Page");
    const navigate  = useNavigate();
    const demoClick = () => {
      navigate('/CYBB'); // redirect to the base directory
    };
    const backClick = () => {
      navigate('/'); // redirect to the base directory
    };
    return (
            <div className="App">
              <header className="App-header">
            <div class="content"> 
                  <div class="start-text">
                  <h1>Work in Progress</h1>
                  <div class="text-content">
                  
                  <p>EmitApps.net is under construction.</p>
                  <p>Click the button below to play "Can You Beat Brad"</p>
                  <br></br>
                  <div class="fun-button">
                  
                  <button onClick={demoClick}>Play</button>
                    </div>          
                  <br></br>
                        
                  </div>
                  <div class= "small-text">
                  <br></br>
                  <p>Contact@EmitApps.net</p>
                  </div>
                  </div>
              </div>
              </header>
            </div>
          );
}

export default HomePage;