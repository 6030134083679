import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
    Features2x3 
   } from '../ui-components';
   import {
    BackButton
   } from '../ui-components';
import { View } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify'
import awsconfig from '../aws-exports';

function DataAnalysisPage() {
  Amplify.configure(awsconfig)
    console.log("Data Page");
    const navigate  = useNavigate();

    const demoClick = () => {
      navigate('/DataAnalysisDemo'); // redirect to the base directory
    };
    const backClick = () => {
      navigate('/'); // redirect to the base directory
    };
    
    return (
            <div className="App">
              <header className="App-header">
              <video controls width="100%" height="auto">
                <source src={"https://lca-it-bucket202945-staging.s3.us-east-2.amazonaws.com/public/TESTVID.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <button onClick={handleOnClick}>
                BACK
              </button>
              <button onClick={handleOnClick}>
                Continue to Demo
              </button> */}
              <button className="basicButton" onClick={demoClick}>Continue to Demo </button>
              <button className="basicButton" onClick={backClick}>Return to Menu </button>
              {/* <View style={{ flexDirection:"row" }}>
                  <button className="basicButton" onClick={handleOnClick}>Return to Menu </button>
                  <button className="basicButton" onClick={handleOnClick}>Continue to Demo </button>
              </View> */}
              </header>
            </div>
          );
}

export default DataAnalysisPage;